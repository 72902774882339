const __request = require(`@/lib/data-service/default/__request/`);
/**
 * 供应商签署
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/esIgn/esIgnSignerController/supplierSign"
    pParameter.data = val;
    pParameter.enable_error_alert = false;
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
