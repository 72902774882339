//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import buyerFileUpload from "@/lib/data-service/default/web_esIgn_esIgnSignerController_buyerFileUpload";
import supplierFileUpload from "@/lib/data-service/default/web_esIgn_esIgnSignerController_supplierFileUpload";
import supplierSign from "@/lib/data-service/default/web_esIgn_esIgnSignerController_supplierSign";
import buyerSign from "@/lib/data-service/default/web_esIgn_esIgnSignerController_buyerSign";
import supplierSignAcc from "@/lib/data-service/default/web_esIgn_esIgnSignerController_supplierSignAcc";
import buyerAcc from "@/lib/data-service/default/web_esIgn_esIgnSignerController_buyerAcc";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info";
import get_url_prefix from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix';
export default {
  data() {
    return {
      account: "",
      img: [
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/distributor/registration%20success.png?x-oss-process=style/webp",
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/distributor/audit.png?x-oss-process=style/webp",
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/distributor/error.png?x-oss-process=style/webp",
        "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/distributor/sign.png?x-oss-process=style/webp"
      ],
      //0 = 提交成功，1 = 审核中， 2 = 签约失败，3 = 审核成功
      type: 0,
      auditStatu: false,
      audit: false,
      btnText: "返回首页",
      accountType: 1, //1 2 供应商 ， 3 4 分销商 5 平台 6游客 7个人分销
      pdfUrl: "",
      time: 10
    };
  },
  methods: {
    goPage() {
      // let url = get_url_prefix({
      //   environment: global.appConfig.environment,
      //   appKey: "default"
      // });
      if (this.type != 3) {
        window.location.href = "/";
      } else {
        if (!this.auditStatu)
          return this.$message({
            type: "warning",
            message: "请阅读并勾选合同文件！"
          });
        this.signContract();
      }
    },
    async signContract() {
      let _this = this;
      if (this.accountType == 1 || this.accountType == 2) {
        try {
          let acc = await supplierSignAcc();
          let res = await supplierSign();
          window.open(res.url);
          this.goSuccess(0);
        } catch (error) {
          this.goSuccess(2);
        }
      } else if (
        this.accountType == 3 ||
        this.accountType == 4 ||
        this.accountType == 7
      ) {
        try {
          let acc = await buyerAcc();
          let res = await buyerSign();
          window.open(res.url);
          this.goSuccess(0);
        } catch (error) {
          this.goSuccess(2);
        }
      }
    },
    goSuccess(type) {
      this.$router.push({ path: `/register/success?type=${type}` });
      setTimeout(() => {
        this.$router.go(0);
      }, 500);
    },
    getPdf() {
      if (this.accountType == 1 || this.accountType == 2) {
        supplierFileUpload().then(res => {
          this.pdfUrl = res;
        });
      } else if (
        this.accountType == 3 ||
        this.accountType == 4 ||
        this.accountType == 7
      ) {
        buyerFileUpload().then(res => {
          this.pdfUrl = res;
        });
      }
    },
    setTime() {
      let count = setInterval(() => {
        this.time -= 1;
        if (this.time == 0) {
          this.time = 0;
          clearInterval(count);
          this.$router.push({ name: "redirect-to-user-front-home" });
        }
      }, 1000);
    },
    goPdf(url) {
      window.open(url);
    }
  },

  async created() {
    let type = (this.type = this.$route.query.type || 0);
    if (type == 3) {
      if (sessionStorage.getItem("pageShow")) {
        this.$router.push;
      }
      get_user_info().then(res => {
        console.log(res);
        this.accountType = res.userVo.userType;
        this.getPdf();
      });
      this.btnText = "完成签约";
    } else if (type == 0) {
      this.setTime();
    }
  }
};
